import axios from "axios";

const API_URL = "https://api.club-spotlight.com/api/subscriptions/";

// Create new subscription
const createSubscription = async (subscriptionData: any, token: any) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await axios.post(API_URL, subscriptionData, config);

    return response.data;
};

const updateSubscriptionDetails = async (subscriptionData: any, token: any) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await axios.patch(API_URL, subscriptionData, config);

    return response.data;
};

const updateSubscriptionPlan = async (subscriptionData: any, token: any) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await axios.patch(
        API_URL + "sub",
        subscriptionData,
        config
    );

    return response.data;
};

const cancelSubscriptionPlan = async (subscriptionData: any, token: any) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await axios.patch(
        API_URL + "cancel",
        subscriptionData,
        config
    );

    return response.data;
};

// Get user subscriptionss
const getSubscriptions = async (token: any) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await axios.get(API_URL, config);

    return response.data;
};

// Get Analytics data for Selected Club
const getClubAnalyticsData = async (_clubsId: string, token: any) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await axios.get(API_URL + "analytics/" + _clubsId, config);

    return response.data;
};

// Delete user subscription
const deleteSubscription = async (subscriptionId: string, token: any) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await axios.delete(API_URL + subscriptionId, config);

    return response.data;
};

const subscriptionsService = {
    createSubscription,
    getSubscriptions,
    deleteSubscription,
    updateSubscriptionDetails,
    updateSubscriptionPlan,
    cancelSubscriptionPlan,
    getClubAnalyticsData,
};

export default subscriptionsService;
