import ReactApexChart, { Props } from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { useEffect, useState } from "react";

const OrderByDayBarChart: React.FC<Props> = () => {
	const { analytics } = useSelector((state: RootState) => state.subscriptions);

	let [series, setSeries] = useState([
		{
			name: "Page visits",
			data: analytics.viewsCountByDayArr,
		},
		{
			name: "Performer Orders",
			data: analytics.performerOrdersCountByDayArr,
		},
		{
			name: "Menu Orders",
			data: analytics.menuOrdersCountByDayArr,
		},
	]);

	let [category, setCategory] = useState(analytics.datesArr);

	//console.log(analytics);
	let [isPopulated, setIsPopulated] = useState(false);

	useEffect(() => {
		if (analytics.length > 0) {
			setIsPopulated(true);
			setSeries([
				{
					name: "Page visits",
					data: analytics.viewsCountByDayArr,
				},
				{
					name: "Performer Orders",
					data: analytics.performerOrdersCountByDayArr,
				},
				{
					name: "Menu Orders",
					data: analytics.menuOrdersCountByDayArr,
				},
			]);

			setCategory(analytics.datesArr);
		}

		
		if (analytics?.length > 0) {
			setIsPopulated(true);
			setSeries([
				{
					name: "Page visits",
					data: analytics.viewsCountByDayArr,
				},
				{
					name: "Performer Orders",
					data: analytics.performerOrdersCountByDayArr,
				},
				{
					name: "Menu Orders",
					data: analytics.menuOrdersCountByDayArr,
				},
			]);

			setCategory(analytics.datesArr);
		}

		if (analytics.length === 0) {
			setIsPopulated(false);
		}
	}, [analytics]);

	const options: ApexOptions = {
		chart: {
			
			height: 350,
			type: "line",
			zoom: {
				enabled: true,
			},
		},
		xaxis: { categories: category },
	};

	return (
		<>
			<div className="py-4 mt-4 container mx-auto px-4 sm:px-6 lg:px-8">
				<div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
					<h2 className="text-lg font-medium leading-6 text-gray-900 text-center mt-3 mb-3">
						Last 14 days
					</h2>
					<ReactApexChart
						options={options}
						series={series}
						type="line"
						height={350}
					/>
				</div>
			</div>
		</>
	);
};

export default OrderByDayBarChart;
