import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../app/store";
import { selectInvoice } from "../features/invoices/invoicesSlice";

export default function AuthTransactions() {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

	const { invoices } = useSelector((state: RootState) => state.invoices);

	let [isPopulated, setIsPopulated] = useState(false);

	useEffect(() => {
		if (invoices.length > 0) {
			setIsPopulated(true);
		}

		if (invoices?.length > 0) {
			setIsPopulated(true);
		}

		if (invoices.length === 0) {
			setIsPopulated(false);
		}
	}, [invoices]);

	function viewInvoice(_id: string) {
		dispatch(selectInvoice(_id));

		navigate("/view-invoice/" + _id);
		console.log(_id);
	}

	return (
		<div className="py-4 px-4 sm:px-6 lg:px-8">
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-xl font-semibold text-gray-900">Transactions</h1>
					<p className="mt-2 text-sm text-gray-700">
						A list of all the invoices from the last 6 months.
					</p>
				</div>
			</div>
			{isPopulated ? (
				<div className="mt-8 flex flex-col">
					<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
							<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="bg-gray-50">
										<tr>
											<th
												scope="col"
												className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
											>
												Invoice
											</th>
											<th
												scope="col"
												className="px-5 py-3.5 text-left text-sm font-semibold text-gray-900"
											>
												Club ID
											</th>
											<th
												scope="col"
												className="px-3.5 py-3.5 text-left text-sm font-semibold text-gray-900"
											>
												Period Start
											</th>
											<th
												scope="col"
												className="relative py-3.5 pl-3 pr-4 sm:pr-6"
											>
												<span className="sr-only">Edit</span>
											</th>
										</tr>
									</thead>
									<tbody className="bg-white">
										{invoices.map((invoice, invoiceIdx) => (
											<tr
												key={invoice._id}
												className={
													invoiceIdx % 2 === 0 ? undefined : "bg-gray-50"
												}
											>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
													{invoice.stripe_invoice_id}
												</td>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
													{invoice._clubsId}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{new Date(invoice.period_start).toLocaleString()}
												</td>

												<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
													<button
														type="button"
														className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
														onClick={() => viewInvoice(invoice._id)}
													>
														<ArrowTopRightOnSquareIcon
															className="-ml-1 mr-3 h-5 w-5"
															aria-hidden="true"
														/>
														View Invoice Details
													</button>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className="text-center overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 ">
					<div className="text-center relative grid gap-6 bg-white px-5 py-2 sm:gap-8 sm:p-8 ">
						<div className="text-center justify-center">
							<h3 className="text-center mt-2 text-sm font-medium text-gray-900">
								No Invoices
							</h3>
							<p className="text-center mt-1 text-sm text-gray-500">
								Get started by adding a subscription
							</p>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
