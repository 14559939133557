import { Fragment } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
export default function ViewInvoiceDetails() {
	const { selectedInvoice } = useSelector((state: RootState) => state.invoices);

	return (
		<>
			<div className="overflow-hidden bg-white shadow sm:rounded-lg px-4 py-5">
				<div className="px-4 py-5 sm:px-6">
					<h3 className="text-lg font-medium leading-6 text-gray-900">
						Invoice Information
					</h3>
					<p className="mt-1 max-w-2xl text-sm text-gray-500">
						View your Invoice
					</p>
				</div>
				<div className="border-t border-gray-200 px-4 py-5 sm:px-6">
					<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
						<div className="sm:col-span-1">
							<dt className="text-sm font-medium text-gray-500">Invoice ID:</dt>
							<dd className="mt-1 text-sm text-gray-900">
								{selectedInvoice.stripe_invoice_id}
							</dd>
						</div>
						<div className="sm:col-span-1">
							<dt className="text-sm font-medium text-gray-500">Club ID:</dt>
							<dd className="mt-1 text-sm text-gray-900">
								{selectedInvoice._clubsId}
							</dd>
						</div>
						<div className="sm:col-span-1">
							<dt className="text-sm font-medium text-gray-500">
								Invoice Amount:
							</dt>
							<dd className="mt-1 text-sm text-gray-900">
								$
								{Math.round((selectedInvoice.amount_due * 100) / 10000).toFixed(
									2
								)}
							</dd>
						</div>
						<div className="sm:col-span-1">
							<dt className="text-sm font-medium text-gray-500">
								Amount Paid:
							</dt>
							<dd className="mt-1 text-sm text-gray-900">
								$
								{Math.round(
									(selectedInvoice.amount_paid * 100) / 10000
								).toFixed(2)}
							</dd>
						</div>
						<div className="sm:col-span-1">
							<dt className="text-sm font-medium text-gray-500">
								Amount Remaining:
							</dt>
							<dd className="mt-1 text-sm text-gray-900">
								$
								{Math.round(
									(selectedInvoice.amount_remaining * 100) / 10000
								).toFixed(2)}
							</dd>
						</div>
						<div className="sm:col-span-1">
							<dt className="text-sm font-medium text-gray-500">Paid at:</dt>
							<dd className="mt-1 text-sm text-gray-900">
								{new Date(
									selectedInvoice.status_transitions.paid_at
								).toLocaleString()}
							</dd>
						</div>
						<div className="sm:col-span-1">
							<dt className="text-sm font-medium text-gray-500">
								Invoice Period:
							</dt>
							<dd className="mt-1 text-sm text-gray-900">
								{new Date(selectedInvoice.period_start).toLocaleString()}
								<br></br>
								to<br></br>
								{new Date(selectedInvoice.period_end).toLocaleString()}
							</dd>
						</div>
						<div className="sm:col-span-1">
							<dt className="text-sm font-medium text-gray-500">
								Hosted Invoice:
							</dt>
							<dd className="mt-1 text-sm text-gray-900">
								{selectedInvoice.hosted_invoice_url}
							</dd>
						</div>
						<div className="sm:col-span-1">
							<dt className="text-sm font-medium text-gray-500">Invoice PDF</dt>
							<dd className="mt-1 text-sm text-gray-900">
								{selectedInvoice.invoice_pdf}
							</dd>
						</div>
					</dl>
				</div>
			</div>
		</>
	);
}
