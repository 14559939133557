import backgroundImage from '../images/background-faqs.jpg'

const faqs = [
  [
    {
      question: 'Does Club Spotlight charge sales tax?',
      answer:
        'We make our best effort to comply with state and local sales tax requirements.',
    },
    {
      question: 'How are subscriptions processed?',
      answer: 'Subscriptions are processed securely through Stripe. We do not save customer credit card data on our servers.',
    },

  ],

  [
    {
      question: 'What if I have multiple clubs?',
      answer:
        'If you have more than one club in need of our services, reach out to us for a custom multi-club quote.',
    },
    {
      question: 'Once we have signed up, how do we access the platform?',
      answer:
        'You can access the Manager portal and sign in to access your tools by following the Manager Portal link at the top of this page. A link will also be emailed to you upon signup.',
    },

  ],


  [
    {
      question: 'How will patron orders be taken?',
      answer: 'Once you have signed up, selected a plan, and confirmed your shipping address we will have your QR code prints shipped to your club where patrons can scan them to access your club site.',
    },
    {
      question: 'How many managers can I have for my club?',
      answer:
        'You can have as many managers on your club as you need. Each will need a unique email address. A club can only have 1 master user, and that will be the email linked to the billing account.',
    },
  ],
]

export function Faqs() {
  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-slate-50 py-20 sm:py-32"
    >
      <img
        className="absolute top-0 left-1/2 max-w-none translate-x-[-30%] -translate-y-1/4"
        src={backgroundImage}
        alt=""
        width={1558}
        height={946}
      />

        <div
        className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faq-title"
            className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl"
          >
            Frequently asked questions
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            If you cannot find what you are looking for, email our support team
            and we will get back to you.
          </p>
        </div>
        <ul
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3"
        >
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul className="flex flex-col gap-y-8">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="font-display text-lg leading-7 text-slate-900">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-sm text-slate-700">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
        </div>
    </section>
  )
}
