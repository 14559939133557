import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../app/store";
import {
	ArrowTopRightOnSquareIcon,
	TrashIcon,
	WalletIcon,
} from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import {
	cancelSubscriptionPlan,
	editSubscriptionDetails,
	editSubscriptionPlan,
} from "../features/subscriptions/subscriptionsSlice";
import { getPrice, getPriceId, getProductId } from "./productStore";

export default function ViewClubDetails() {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

	const { selectedSubscription } = useSelector(
		(state: RootState) => state.subscriptions
	);

	let [isOpenEdit, setIsOpenEdit] = useState(false);

	function openModalEdit() {
		setIsOpenEdit(true);
	}

	function closeModalEdit() {
		setIsOpenEdit(false);
	}

	let [isOpenCancel, setIsOpenCancel] = useState(false);

	function openModalCancel() {
		setIsOpenCancel(true);
	}

	function closeModalCancel() {
		setIsOpenCancel(false);
	}

	let [isOpenChangePlan, setIsOpenChangePlan] = useState(false);

	function openModalChangePlan() {
		setIsOpenChangePlan(true);
	}

	function closeModalChangePlan() {
		setIsOpenChangePlan(false);
	}

	const [formData, setFormData] = useState({
		_id: selectedSubscription._id,
		stripe_subscription: selectedSubscription.stripe_subscription,
		user_id: selectedSubscription.user_id,
		_clubsId: selectedSubscription._clubsId,
		product_id: selectedSubscription.product_id,
		price_id: selectedSubscription.price_id,
		order_timestamp: selectedSubscription.order_timestamp,
		plan_name: selectedSubscription.plan_name,
		location_name: selectedSubscription.location_name,
		location_address: selectedSubscription.location_address,
		location_city: selectedSubscription.location_city,
		location_state: selectedSubscription.location_state,
		location_postal_code: selectedSubscription.location_postal_code,
		description: selectedSubscription.description,
	});

	const [priceData, setPriceData] = useState({
		plan_name: selectedSubscription.plan_name,
		price: getPrice(selectedSubscription.plan_name),
	});

	const {
		_id,
		stripe_subscription,
		user_id,
		_clubsId,
		product_id,
		price_id,
		plan_name,
		order_timestamp,
		location_name,
		location_address,
		location_city,
		location_state,
		location_postal_code,
		description,
	} = formData;

	const { user } = useSelector((state: RootState) => state.auth);

	const onChange = (e: { target: { name: any; value: any } }) => {
		setFormData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	const onChangeDropdown = (e: { target: { name: any; value: any } }) => {
		setFormData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));

		setPriceData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
			price: getPrice(e.target.value),
		}));
	};

	const handleSubmitSubEdit = async (e: { preventDefault: () => void }) => {
		e.preventDefault();

		const editData = {
			_id,
			stripe_subscription,
			user_id,
			_clubsId,
			product_id,
			price_id,
			plan_name,
			order_timestamp,
			location_name,
			location_address,
			location_city,
			location_state,
			location_postal_code,
			description,
		};

		//axios.post('http://localhost:3000/api/subscriptions/sub', subscriptionData)

		console.log(editData);

		dispatch(editSubscriptionDetails(editData));

		closeModalEdit();
	};

	const handleSubmitSubCancel = async (e: { preventDefault: () => void }) => {
		e.preventDefault();

		const editData = {
			_id,
			stripe_subscription,
			user_id,
			_clubsId,
			product_id,
			price_id,
			plan_name,
			order_timestamp,
			location_name,
			location_address,
			location_city,
			location_state,
			location_postal_code,
			description,
		};

		console.log(editData);

		dispatch(cancelSubscriptionPlan(editData));

		closeModalCancel();
	};

	const handleSubmitSubChangePlan = async (e: {
		preventDefault: () => void;
	}) => {
		e.preventDefault();

		const editData = {
			_id,
			stripe_subscription,
			user_id,
			_clubsId,
			product_id,
			price_id,
			plan_name,
			order_timestamp,
			location_name,
			location_address,
			location_city,
			location_state,
			location_postal_code,
			description,
		};

		editData.product_id = getProductId(editData.plan_name);
		editData.price_id = getPriceId(editData.plan_name);
		//editData.price = getPrice(editData.plan_name);

		console.log(editData);

		dispatch(editSubscriptionPlan(editData));

		closeModalChangePlan();
	};

	return (
		<>
			<div className="overflow-hidden bg-white shadow sm:rounded-lg px-4 py-5">
				<div className="px-4 py-5 sm:px-6">
					<h3 className="text-lg font-medium leading-6 text-gray-900">
						Subscription Information
					</h3>
					<p className="mt-1 max-w-2xl text-sm text-gray-500">
						View and manage your subscriptions
					</p>
				</div>
				<div className="border-t border-gray-200 px-4 py-5 sm:px-6">
					<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
						<div className="sm:col-span-1">
							<dt className="text-sm font-medium text-gray-500">
								Subscription ID:
							</dt>
							<dd className="mt-1 text-sm text-gray-900">
								{selectedSubscription._id}
							</dd>
						</div>
						<div className="sm:col-span-1">
							<dt className="text-sm font-medium text-gray-500">Club ID:</dt>
							<dd className="mt-1 text-sm text-gray-900">
								{selectedSubscription._clubsId}
							</dd>
						</div>
						<div className="sm:col-span-1">
							<dt className="text-sm font-medium text-gray-500">Club Name:</dt>
							<dd className="mt-1 text-sm text-gray-900">
								{selectedSubscription.location_name}
							</dd>
						</div>
						<div className="sm:col-span-1">
							<dt className="text-sm font-medium text-gray-500">
								Subscribed since:
							</dt>
							<dd className="mt-1 text-sm text-gray-900">
								{new Date(selectedSubscription.createdAt).toLocaleString()}
							</dd>
						</div>
						<div className="sm:col-span-1">
							<dt className="text-sm font-medium text-gray-500">Address:</dt>
							<dd className="mt-1 text-sm text-gray-900">
								{selectedSubscription.location_address}
							</dd>
						</div>
						<div className="sm:col-span-1">
							<dt className="text-sm font-medium text-gray-500">City:</dt>
							<dd className="mt-1 text-sm text-gray-900">
								{selectedSubscription.location_city}
							</dd>
						</div>
						<div className="sm:col-span-1">
							<dt className="text-sm font-medium text-gray-500">State:</dt>
							<dd className="mt-1 text-sm text-gray-900">
								{selectedSubscription.location_state}
							</dd>
						</div>
						<div className="sm:col-span-1">
							<dt className="text-sm font-medium text-gray-500">Zip Code:</dt>
							<dd className="mt-1 text-sm text-gray-900">
								{selectedSubscription.location_postal_code}
							</dd>
						</div>
						<div className="sm:col-span-1">
							<dt className="text-sm font-medium text-gray-500">Plan:</dt>
							<dd className="mt-1 text-sm text-gray-900">
								{selectedSubscription.plan_name}
							</dd>
						</div>
						<div className="sm:col-span-1">
							<dt className="text-sm font-medium text-gray-500">
								Current Subscription period:
							</dt>
							<dd className="mt-1 text-sm text-gray-900">
								{new Date(selectedSubscription.period_start).toLocaleString()}{" "}
								to {new Date(selectedSubscription.period_end).toLocaleString()}
							</dd>
						</div>
						<div className="sm:col-span-2">
							<dt className="text-sm font-medium text-gray-500">
								Club Description:
							</dt>
							<dd className="mt-1 text-sm text-gray-900">
								{selectedSubscription.description}
							</dd>
						</div>
					</dl>
				</div>

				<div className="mt-5 sm:mt-4 sm:flex ">
					<div className="mt-4 sm:mt-0 sm:ml-4 sm:flex-none">
						<button
							onClick={openModalEdit}
							type="button"
							className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
						>
							<span className="mr-3 inset-y-0 left-0 flex items-start pl-3">
								<ArrowTopRightOnSquareIcon
									className="  h-5 w-5 text-indigo-100 group-hover:text-indigo-400"
									aria-hidden="true"
								/>
							</span>
							Edit Details
						</button>
					</div>

					<div className="mt-4 sm:mt-0 sm:ml-4 sm:flex-none">
						<button
							onClick={openModalChangePlan}
							type="button"
							className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
						>
							<span className="mr-3 inset-y-0 left-0 flex items-start pl-3">
								<WalletIcon
									className="  h-5 w-5 text-indigo-100 group-hover:text-indigo-400"
									aria-hidden="true"
								/>
							</span>
							Change Subscription Plan
						</button>
					</div>

					<div className="mt-4 sm:mt-0 sm:ml-4 sm:flex-none">
						<button
							onClick={openModalCancel}
							className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
						>
							<span className="mr-3 inset-y-0 left-0 flex items-start pl-3">
								<TrashIcon
									className="  h-5 w-5 text-indigo-100 group-hover:text-indigo-400"
									aria-hidden="true"
								/>
							</span>
							End Subscription
						</button>
					</div>
				</div>

				<Transition appear show={isOpenEdit} as={Fragment}>
					<Dialog as="div" className="relative z-10" onClose={closeModalEdit}>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0 bg-black bg-opacity-25" />
						</Transition.Child>

						<div className="fixed inset-0 overflow-y-auto">
							<div className="flex min-h-full items-center justify-center p-4 text-center">
								<Transition.Child
									as={Fragment}
									enter="ease-out duration-300"
									enterFrom="opacity-0 scale-95"
									enterTo="opacity-100 scale-100"
									leave="ease-in duration-200"
									leaveFrom="opacity-100 scale-100"
									leaveTo="opacity-0 scale-95"
								>
									<Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-4xl bg-white p-6 text-left align-middle shadow-xl transition-all">
										<Dialog.Title
											as="h3"
											className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900"
										>
											Edit Subscription Details
										</Dialog.Title>
										<div className="mt-2">
											<div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
												<div className="w-full max-w-3xl space-y-8">
													<form
														className="mt-8 space-y-6"
														action="#"
														method="POST"
													>
														<input
															type="hidden"
															name="remember"
															defaultValue="true"
															onChange={onChange}
														/>
														<div className="-space-y-px rounded-md shadow-sm">
															<div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
																<div className="md:grid md:grid-cols-3 md:gap-6">
																	<div className="col-span-6 sm:col-span-4">
																		<label
																			htmlFor="location_name"
																			className="block text-sm font-medium text-gray-700"
																		>
																			Club Name
																		</label>
																		<input
																			id="location_name"
																			name="location_name"
																			type="location_name"
																			required
																			className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
																			placeholder={
																				selectedSubscription.location_name
																			}
																			onChange={onChange}
																		/>
																	</div>

																	<div className="col-span-6 sm:col-span-4">
																		<label
																			htmlFor="location_address"
																			className="block text-sm font-medium text-gray-700"
																		>
																			Street Address
																		</label>
																		<input
																			id="location_address"
																			name="location_address"
																			type="location_address"
																			required
																			className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
																			placeholder={
																				selectedSubscription.location_address
																			}
																			onChange={onChange}
																		/>
																	</div>

																	<div className="col-span-6 sm:col-span-6 lg:col-span-2">
																		<label
																			htmlFor="location_city"
																			className="block text-sm font-medium text-gray-700"
																		>
																			City
																		</label>
																		<input
																			id="location_city"
																			name="location_city"
																			type="location_city"
																			required
																			className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
																			placeholder={
																				selectedSubscription.location_city
																			}
																			onChange={onChange}
																		/>
																	</div>

																	<div className="col-span-6 sm:col-span-6 lg:col-span-2">
																		<label
																			htmlFor="location_state"
																			className="block text-sm font-medium text-gray-700"
																		>
																			State Abbreviation
																		</label>
																		<input
																			id="location_state"
																			name="location_state"
																			type="location_state"
																			maxLength={2}
																			required
																			className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
																			placeholder={
																				selectedSubscription.location_state
																			}
																			onChange={onChange}
																		/>
																	</div>

																	<div className="col-span-6 sm:col-span-6 lg:col-span-2">
																		<label
																			htmlFor="location_postal_code"
																			className="block text-sm font-medium text-gray-700"
																		>
																			Postal Code
																		</label>
																		<input
																			id="location_postal_code"
																			name="location_postal_code"
																			type="location_postal_code"
																			required
																			className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
																			placeholder={
																				selectedSubscription.location_postal_code
																			}
																			onChange={onChange}
																		/>
																	</div>

																	<div className="col-span-6 sm:col-span-6 lg:col-span-6">
																		<label
																			htmlFor="description"
																			className="block text-sm font-medium text-gray-700"
																		>
																			Club Description
																		</label>
																		<input
																			id="description"
																			name="description"
																			type="description"
																			required
																			className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
																			placeholder={
																				selectedSubscription.description
																			}
																			onChange={onChange}
																		/>
																	</div>
																</div>
															</div>
														</div>

														<div className="mt-4 flex ">
															<button
																type="button"
																className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
																onClick={closeModalEdit}
															>
																Cancel
															</button>

															<button
																type="submit"
																onClick={handleSubmitSubEdit}
																className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
															>
																Submit changes
															</button>
														</div>
													</form>
												</div>
											</div>
										</div>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</Dialog>
				</Transition>

				<Transition appear show={isOpenCancel} as={Fragment}>
					<Dialog as="div" className="relative z-10" onClose={closeModalCancel}>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0 bg-black bg-opacity-25" />
						</Transition.Child>

						<div className="fixed inset-0 overflow-y-auto">
							<div className="flex min-h-full items-center justify-center p-4 text-center">
								<Transition.Child
									as={Fragment}
									enter="ease-out duration-300"
									enterFrom="opacity-0 scale-95"
									enterTo="opacity-100 scale-100"
									leave="ease-in duration-200"
									leaveFrom="opacity-100 scale-100"
									leaveTo="opacity-0 scale-95"
								>
									<Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-4xl bg-white p-6 text-left align-middle shadow-xl transition-all">
										<Dialog.Title
											as="h3"
											className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900"
										>
											End Subscription
										</Dialog.Title>
										<div className="mt-2">
											<div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
												<div className="w-full max-w-3xl space-y-8">
													<div className="bg-white shadow sm:rounded-lg">
														<div className="px-4 py-5 sm:p-6">
															<h3 className="text-lg font-medium leading-6 text-gray-900">
																Confirm Subscription Cancellation
															</h3>
															<div className="mt-2 max-w-xl text-sm text-gray-500">
																<p>
																	Once you cancel your subscription, you will
																	lose all data associated with it at the end of
																	the current billing period.
																</p>
															</div>
															<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
																<div className="px-5">
																	<button
																		type="button"
																		className=" mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
																		onClick={closeModalCancel}
																	>
																		Cancel
																	</button>
																</div>

																<button
																	type="button"
																	className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
																	onClick={handleSubmitSubCancel}
																>
																	Cancel Subscription
																</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</Dialog>
				</Transition>

				<Transition appear show={isOpenChangePlan} as={Fragment}>
					<Dialog
						as="div"
						className="relative z-10"
						onClose={closeModalChangePlan}
					>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0 bg-black bg-opacity-25" />
						</Transition.Child>

						<div className="fixed inset-0 overflow-y-auto">
							<div className="flex min-h-full items-center justify-center p-4 text-center">
								<Transition.Child
									as={Fragment}
									enter="ease-out duration-300"
									enterFrom="opacity-0 scale-95"
									enterTo="opacity-100 scale-100"
									leave="ease-in duration-200"
									leaveFrom="opacity-100 scale-100"
									leaveTo="opacity-0 scale-95"
								>
									<Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-4xl bg-white p-6 text-left align-middle shadow-xl transition-all">
										<Dialog.Title
											as="h3"
											className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900"
										>
											Change Subscription Plan
										</Dialog.Title>
										<div className="mt-2">
											<div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
												<div className="w-full max-w-3xl space-y-8">
													<form
														className="mt-8 space-y-6"
														action="#"
														method="POST"
													>
														<input
															type="hidden"
															name="remember"
															defaultValue="true"
															onChange={onChange}
														/>
														<div className="-space-y-px rounded-md shadow-sm">
															<div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
																<div className="md:grid md:grid-cols-3 md:gap-6">
																	<div className="col-span-6 sm:col-span-4">
																		<label
																			htmlFor="plan_name"
																			className="block text-sm font-medium text-gray-700"
																		>
																			Plan
																		</label>
																		<select
																			id="plan_name"
																			onChange={onChangeDropdown}
																			name="plan_name"
																			placeholder={
																				selectedSubscription.plan_name
																			}
																			defaultValue={
																				selectedSubscription.plan_name
																			}
																			className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
																		>
																			<option>Basic</option>
																			<option>Management plus</option>
																			<option>Premium</option>
																		</select>
																	</div>
																</div>

																<div className="flex items-center justify-between pt-8">
																	<dt className="text-sm text-gray-600 ">
																		Subscription Price
																	</dt>
																	<dd className="text-sm font-medium text-gray-900 ">
																		$
																		{Math.round(
																			(priceData.price * 100) / 100
																		).toFixed(2)}
																	</dd>
																</div>
															</div>
														</div>

														<div className="mt-4 flex ">
															<button
																type="button"
																className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
																onClick={closeModalChangePlan}
															>
																Cancel
															</button>

															<button
																type="submit"
																onClick={handleSubmitSubChangePlan}
																className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
															>
																Submit changes
															</button>
														</div>
													</form>
												</div>
											</div>
										</div>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</Dialog>
				</Transition>
			</div>
		</>
	);
}
