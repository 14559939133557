import { useId } from "react";
import { Tab } from "@headlessui/react";
import clsx from "clsx";
import screenshotContacts from "../images/screenshots/contacts.png";
import screenshotInventory from "../images/screenshots/inventory.png";
import screenshotProfitLoss from "../images/screenshots/profit-loss.png";
import dynamic_posting from "../images/real_screenshots/dynamic_posting.png";
import coming_soon from "../images/real_screenshots/coming_soon4.jpg";
import drink_requests from "../images/real_screenshots/drink_requests.png";
import analytics from "../images/real_screenshots/analytics.png";

const features = [
	{
		name: "Analytics",
		summary: "Stay on top of club activity with real-time analytics.",
		description:
			"Club Spotlight comes with real-time analytics to help you measure what your club is doing right.",
		image: analytics,

		icon: function ReportingIcon() {
			let id = useId();
			return (
				<>
					<defs>
						<linearGradient
							id={id}
							x1="11.5"
							y1={18}
							x2={36}
							y2="15.5"
							gradientUnits="userSpaceOnUse"
						>
							<stop offset=".194" stopColor="#fff" />
							<stop offset={1} stopColor="#6692F1" />
						</linearGradient>
					</defs>
					<path
						d="m30 15-4 5-4-11-4 18-4-11-4 7-4-5"
						stroke={`url(#${id})`}
						strokeWidth={2}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</>
			);
		},
	},
	{
		name: "Realtime requests",
		summary: "Improve bar sales and fee revenues.",
		description:
			"Getting your patrons exactly the service they are looking for, exactly when they want it helps you improve your topline.",
		image: drink_requests,
		icon: function InventoryIcon() {
			return (
				<>
					<path
						d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
						fill="#fff"
					/>
				</>
			);
		},
	},
	{
		name: "Contacts",
		summary:
			"Coming soon, maintain access to patron contact information to share news and promotions ",
		description:
			"With our premium service, you can collect patron contact information, add them to distribtion lists and send text or email messages to keep them up to date with your club.",
		image: coming_soon,
		icon: function ContactsIcon() {
			return (
				<>
					<path
						opacity=".5"
						d="M25.778 25.778c.39.39 1.027.393 1.384-.028A11.952 11.952 0 0 0 30 18c0-6.627-5.373-12-12-12S6 11.373 6 18c0 2.954 1.067 5.659 2.838 7.75.357.421.993.419 1.384.028.39-.39.386-1.02.036-1.448A9.959 9.959 0 0 1 8 18c0-5.523 4.477-10 10-10s10 4.477 10 10a9.959 9.959 0 0 1-2.258 6.33c-.35.427-.354 1.058.036 1.448Z"
						fill="#fff"
					/>
					<path
						d="M12 28.395V28a6 6 0 0 1 12 0v.395A11.945 11.945 0 0 1 18 30c-2.186 0-4.235-.584-6-1.605ZM21 16.5c0-1.933-.5-3.5-3-3.5s-3 1.567-3 3.5 1.343 3.5 3 3.5 3-1.567 3-3.5Z"
						fill="#fff"
					/>
				</>
			);
		},
	},
];

function Feature({
	feature,
	isActive,
	className,
	...props
}: {
	feature: any;
	isActive: any;
	className: any;
}) {
	return (
		<div
			className={clsx(className, !isActive && "opacity-75 hover:opacity-100")}
			{...props}
		>
			<div
				className={clsx(
					"w-9 rounded-lg",
					isActive ? "bg-blue-600" : "bg-slate-500"
				)}
			>
				<svg aria-hidden="true" className="h-9 w-9" fill="none">
					<feature.icon />
				</svg>
			</div>
			<h3
				className={clsx(
					"mt-6 text-sm font-medium",
					isActive ? "text-blue-600" : "text-slate-600"
				)}
			>
				{feature.name}
			</h3>
			<p className="mt-2 font-display text-xl text-slate-900">
				{feature.summary}
			</p>
			<p className="mt-4 text-sm text-slate-600">{feature.description}</p>
		</div>
	);
}

function FeaturesMobile() {
	return (
		<div className="-mx-4 mt-20 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden">
			{features.map((feature) => (
				<div key={feature.name}>
					<Feature feature={feature} className="mx-auto max-w-2xl" isActive />
					<div className="relative mt-10 pb-10">
						<div className="absolute -inset-x-4 bottom-0 top-8 bg-slate-200 sm:-inset-x-6" />
						<div className="relative mx-auto w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10">
							<img
								className="w-full"
								src={feature.image}
								alt=""
								sizes="52.75rem"
							/>
						</div>
					</div>
				</div>
			))}
		</div>
	);
}

function FeaturesDesktop() {
	return (
		<Tab.Group as="div" className="hidden lg:mt-20 lg:block">
			{({ selectedIndex }) => (
				<>
					<Tab.List className="grid grid-cols-3 gap-x-8">
						{features.map((feature, featureIndex) => (
							<Feature
								key={feature.name}
								feature={{
									...feature,
									name: (
										<Tab className="[&:not(:focus-visible)]:focus:outline-none">
											<span className="absolute inset-0" />
											{feature.name}
										</Tab>
									),
								}}
								isActive={featureIndex === selectedIndex}
								className="relative"
							/>
						))}
					</Tab.List>
					<Tab.Panels className="relative mt-20 overflow-hidden rounded-4xl bg-slate-200 px-14 py-16 xl:px-16">
						<div className="-mx-5 flex">
							{features.map((feature, featureIndex) => (
								<Tab.Panel
									static
									key={feature.name}
									className={clsx(
										"px-5 transition duration-500 ease-in-out [&:not(:focus-visible)]:focus:outline-none",
										featureIndex !== selectedIndex && "opacity-60"
									)}
									style={{ transform: `translateX(-${selectedIndex * 100}%)` }}
									aria-hidden={featureIndex !== selectedIndex}
								>
									<div className="w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10">
										<img
											className="w-full"
											src={feature.image}
											alt=""
											sizes="52.75rem"
										/>
									</div>
								</Tab.Panel>
							))}
						</div>
						<div className="pointer-events-none absolute inset-0 rounded-4xl ring-1 ring-inset ring-slate-900/10" />
					</Tab.Panels>
				</>
			)}
		</Tab.Group>
	);
}

export function SecondaryFeatures() {
	return (
		<section
			id="secondary-features"
			aria-label="Features for simplifying everyday business tasks"
			className="pt-20 pb-8 sm:pb-12 sm:pt-12 lg:pb-12"
		>
			<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
				<div className="mx-auto max-w-2xl md:text-center">
					<h2 className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">
						Streamline your club management process.
					</h2>
					<p className="mt-4 text-lg tracking-tight text-slate-700">
						Remove indecision from running the floor. All the data and tools you
						need in the palm of your hand.
					</p>
				</div>
				<FeaturesMobile />
				<FeaturesDesktop />
			</div>
		</section>
	);
}
