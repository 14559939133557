import axios from 'axios'

const API_URL = '/api/cart/'

// Add item to cart
const createCartItem = async (cartItemData: any, token: any) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(API_URL, cartItemData, config)

  return response.data
}

// Get user Cart
const getCart = async (token: any) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL, config)

  return response.data
}

// Delete cart item
const deleteCartItem = async (cartItemId: string, token: any) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + cartItemId, config)

  return response.data
}

const cartService = {
  createCartItem,
  getCart,
  deleteCartItem,
}

export default cartService