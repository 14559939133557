import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useNavigate, useParams } from "react-router-dom";
import {
	ChartBarIcon,
	BuildingStorefrontIcon,
	ShoppingCartIcon,
} from "@heroicons/react/20/solid";
import { FooterAuth } from "../components/FooterAuth";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../app/store";
import { Fragment } from "react";
import { Dialog, Tab, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { cartReset } from "../features/cart/cartSlice";
import { getSubscriptions } from "../features/subscriptions/subscriptionsSlice";
import ViewClubDetails from "../components/viewClubDetails";
import ViewClubAnalytics from "../components/viewClubAnalytics";
import AuthViewInvoiceHeader from "../components/authViewInvoiceHeader";
import ViewInvoiceDetails from "../components/viewInvoiceDetails";

const ViewInvoice = () => {
	let { subscriptionId } = useParams();

	const { user } = useSelector((state: RootState) => state.auth);

	const { subscriptions } = useSelector(
		(state: RootState) => state.subscriptions
	);

	return (
		<>
			<AuthViewInvoiceHeader />
			<ViewInvoiceDetails />
			<FooterAuth />
		</>
	);
};

export default ViewInvoice;
