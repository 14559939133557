import { Dialog, Transition } from "@headlessui/react";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { AppDispatch, RootState } from "../app/store";
import { editUserPassword } from "../features/auth/authSlice";
import { ChangePasswordModel } from "../models/change-password.model";

export default function ChangePassword() {
	const { user } = useSelector((state: RootState) => state.auth);
	const dispatch = useDispatch<AppDispatch>();
	let [isOpenEdit, setIsOpenEdit] = useState(false);

	const [passwordShown, setPasswordShown] = useState(false);
	const togglePasswordVisiblity = () => {
		setPasswordShown(passwordShown ? false : true);
	};

	function openModalEdit() {
		setIsOpenEdit(true);
	}

	function closeModalEdit() {
		setIsOpenEdit(false);
	}

	const [formData, setFormData] = useState({
		old_password: "",
		new_password: "",
		confirm_password: "",
	});

	const { old_password, new_password, confirm_password } = formData;

	const onChange = (e: { target: { name: any; value: any } }) => {
		setFormData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	const handleSubmitUserEdit = async (e: { preventDefault: () => void }) => {
		e.preventDefault();
		const _id = user._id;
		if (new_password !== confirm_password) {
			toast.error("Passwords do not match");
		} else {
			const editData: ChangePasswordModel = {
				old_password,
				new_password,
				_id,
			};

			console.log(editData);
			dispatch(editUserPassword(editData));

			closeModalEdit();
		}
	};

	return (
		<>
			<div className="overflow-hidden bg-white shadow sm:rounded-lg px-4 py-5">
				<div className="px-4 py-5 sm:px-6">
					<h3 className="text-lg font-medium leading-6 text-gray-900">
						Password Management
					</h3>
					<p className="mt-1 max-w-2xl text-sm text-gray-500">
						Change User Password
					</p>
				</div>
				<div className="border-t border-gray-200 px-4 py-5 sm:px-6">
					<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
						<div className="sm:col-span-1">
							<dt className="text-sm font-medium text-gray-500">User ID:</dt>
							<dd className="mt-1 text-sm text-gray-900">{user._id}</dd>
						</div>
						<div className="sm:col-span-1">
							<dt className="text-sm font-medium text-gray-500">Email:</dt>
							<dd className="mt-1 text-sm text-gray-900">{user.email}</dd>
						</div>
					</dl>
				</div>
				<div className="mt-5 sm:mt-4 sm:flex ">
					<div className="mt-4 sm:mt-0 sm:ml-4 sm:flex-none">
						<button
							onClick={openModalEdit}
							type="button"
							className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
						>
							<span className="mr-3 inset-y-0 left-0 flex items-start pl-3">
								<LockClosedIcon
									className="  h-5 w-5 text-indigo-100 group-hover:text-indigo-400"
									aria-hidden="true"
								/>
							</span>
							Change Password
						</button>
					</div>
				</div>
			</div>
			<Transition appear show={isOpenEdit} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={closeModalEdit}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-4xl bg-white p-6 text-left align-middle shadow-xl transition-all">
									<Dialog.Title
										as="h3"
										className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900"
									>
										Change Password
									</Dialog.Title>
									<div className="mt-2">
										<div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
											<div className="w-full max-w-3xl space-y-8">
												<form
													className="mt-8 space-y-6"
													action="#"
													method="POST"
												>
													<input
														type="hidden"
														name="remember"
														defaultValue="true"
														onChange={onChange}
													/>
													<div className="-space-y-px rounded-md shadow-sm">
														<div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
															<div className="md:grid md:grid-cols-3 md:gap-6">
																<div className="col-span-6 sm:col-span-4">
																	<label
																		htmlFor="old_password"
																		className="block text-sm font-medium text-gray-700"
																	>
																		Old Password
																	</label>
																	<input
																		id="old_password"
																		name="old_password"
																		type={passwordShown ? "text" : "password"}
																		required
																		className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
																		onChange={onChange}
																	/>
																</div>

																<div className="col-span-6 sm:col-span-4">
																	<label
																		htmlFor="new_password"
																		className="block text-sm font-medium text-gray-700"
																	>
																		New Password
																	</label>
																	<input
																		id="new_password"
																		name="new_password"
																		type={passwordShown ? "text" : "password"}
																		required
																		className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
																		onChange={onChange}
																	/>
																</div>

																<div className="col-span-6 sm:col-span-4">
																	<label
																		htmlFor="confirm_password"
																		className="block text-sm font-medium text-gray-700"
																	>
																		Confirm Password
																	</label>
																	<input
																		id="confirm_password"
																		name="confirm_password"
																		type={passwordShown ? "text" : "password"}
																		required
																		className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
																		onChange={onChange}
																	/>
																</div>
															</div>
														</div>
													</div>

													<div className="flex items-center justify-between">
														<div className="flex items-center">
															<input
																id="show-password"
																name="show-password"
																type="checkbox"
																onChange={togglePasswordVisiblity}
																className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
															/>
															<label
																htmlFor="show-password"
																className="ml-2 block text-sm text-gray-900"
															>
																Show Password
															</label>
														</div>
													</div>

													<div className="mt-4 flex ">
														<button
															type="button"
															className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
															onClick={closeModalEdit}
														>
															Cancel
														</button>

														<button
															type="submit"
															onClick={handleSubmitUserEdit}
															className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
														>
															Submit changes
														</button>
													</div>
												</form>
											</div>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	);
}
