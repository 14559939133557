import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import { RootState } from "../app/store";
import { CallToAction } from "../components/CallToAction";
import Contact from "../components/contact";
import { Faqs } from "../components/Faqs";
import { Footer } from "../components/Footer";
import { HeaderPreAuth } from "../components/HeaderPreAuth";
import { Hero } from "../components/Hero";
import { Pricing } from "../components/Pricing";
import { PrimaryFeatures } from "../components/PrimaryFeatures";
import { SecondaryFeatures } from "../components/SecondaryFeatures";
import VideoDemo from "../components/videoDemo";

export default function DashboardPage() {
	const navigate = useNavigate();

	const { user } = useSelector((state: RootState) => state.auth);

	useEffect(() => {
		if (user) {
			navigate("/user");
		}
	});

	return (
		<>
			<HeaderPreAuth />
			<Hero />
			<PrimaryFeatures />
			<SecondaryFeatures />
			<VideoDemo />
			<CallToAction />
			<Pricing />
			<Contact />
			<Faqs />

			<Routes>
				<Route path="/#features" element={<PrimaryFeatures />} />
				<Route path="/#pricing" element={<Pricing />} />
			</Routes>
			<Footer />
		</>
	);
}
