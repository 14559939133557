import axios from "axios";

const API_URL = "https://api.club-spotlight.com/api/invoices/";

// Get user subscriptionss
const getInvoices = async (token: any) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await axios.get(API_URL, config);

    return response.data;
};

const invoicesService = {
    getInvoices,
};

export default invoicesService;
