export default function VideoDemo() {
	return (
		<div className="bg-white py-8 sm:py-8 m-10">
			<div className="mx-auto max-w-7xl px-6 lg:px-8">
				<div className="mx-auto max-w-2xl space-y-16 divide-y divide-gray-100 lg:mx-0 lg:max-w-none">
					<div>
						<h2 className="text-3xl font-bold tracking-tight text-gray-900">
							Video Demonstration
						</h2>
						<p className="mt-4 leading-7 text-gray-600">
							See how Spotlight can help you and your club
						</p>
					</div>
				</div>
			</div>
			<iframe
				className="w-9/12 aspect-video hover:aspect-square"
				//width="560"
				//height="315"
				src="https://www.youtube.com/embed/CJMG6n5zEBU?si=CYmmFsGpmUT5ZVYQ"
				title="YouTube video player"
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				referrerPolicy="strict-origin-when-cross-origin"
				allowFullScreen
			></iframe>
		</div>
	);
}
