import { createSlice, createAsyncThunk, PayloadAction,  } from '@reduxjs/toolkit'
import cartService from './cartService'
import { CartItem } from '../../models/cart-item.model'
import { RootState } from '../../app/store'

const initialState = {
  cart: [] as CartItem[],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Add cart item
export const createCartItem = createAsyncThunk<
CartItem,
CartItem[],
{ state: RootState}>(
  'cart/create',
  async (cartItemData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await cartService.createCartItem(cartItemData, token)
    } catch (error) {
        let message = 'Unknown Error'
        if (error instanceof Error) message =
        (error.message &&
          error.name &&
          error.stack) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Get Cart
export const getCart = createAsyncThunk<
CartItem[],
string,
{ state: RootState}>(
  'cart/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await cartService.getCart(token)
    } catch (error) {
        let message = 'Unknown Error'
        if (error instanceof Error) message =
        (error.message &&
          error.name &&
          error.stack) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Delete cart item
export const deleteCartItem = createAsyncThunk<
CartItem,
string,
{ state: RootState}>(
  'cart/delete',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await cartService.deleteCartItem(id, token)
    } catch (error) {
        let message = 'Unknown Error'
        if (error instanceof Error) message =
        (error.message &&
          error.name &&
          error.stack) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)


export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    reset: (state) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = false
        state.message = ''
      },
    createTempCart: (state, action: PayloadAction<CartItem>) => {state.cart.push(action.payload)},
cartReset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCartItem.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createCartItem.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        
        state.cart.push(action.payload)
      })
      .addCase(createCartItem.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        if (typeof action.payload === 'string') {state.message =  action.payload}
      })
      .addCase(getCart.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getCart.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.cart = action.payload
      })
      .addCase(getCart.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        if (typeof action.payload === 'string') {state.message =  action.payload}
      })
      .addCase(deleteCartItem.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteCartItem.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.cart = state.cart.filter(
          (cartItem) => cartItem._id !== action.payload._id
        )
      })
      .addCase(deleteCartItem.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        if (typeof action.payload === 'string') {state.message =  action.payload}
      })
  },
})

export const { reset } = cartSlice.actions
export const { cartReset } = cartSlice.actions
export const { createTempCart } = cartSlice.actions
export default cartSlice.reducer