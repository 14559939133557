import { Fragment, useEffect } from "react";
import { Tab } from "@headlessui/react";
import { UserIcon, LockClosedIcon } from "@heroicons/react/20/solid";
import { FooterAuth } from "../components/FooterAuth";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../app/store";
import { reset } from "../features/subscriptions/subscriptionsSlice";
import { toast } from "react-toastify";
import UserDetails from "../components/userDetails";
import AuthViewUserHeader from "../components/authViewUserHeader";
import ChangePassword from "../components/changePassword";

export default function ViewProfile() {
	const dispatch = useDispatch<AppDispatch>();

	const navigate = useNavigate();

	const { user, isError, message } = useSelector(
		(state: RootState) => state.auth
	);

	useEffect(() => {
		if (!user) {
			navigate("/");
		}

		if (isError) {
			toast.error(message);
		}

		return () => {
			dispatch(reset());
		};
	}, [user, isError, message, dispatch, navigate]);

	return (
		<>
			<AuthViewUserHeader />

			<Tab.Group>
				<div>
					<div>
						<div className="border-b border-gray-200">
							<Tab.List className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
								<Tab as={Fragment}>
									{({ selected }) => (
										/* Use the `selected` state to conditionally style the selected tab. */
										<button
											className={
												selected
													? "border-indigo-500 text-indigo-600 group inline-flex items-center py-5 px-6 border-b-2 font-medium text-sm"
													: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 group inline-flex items-center py-5 px-6 border-b-2 font-medium text-sm"
											}
										>
											<UserIcon
												className={
													selected
														? "text-indigo-500 -ml-0.5 mr-2 h-5 w-5"
														: "text-gray-400 group-hover:text-gray-500 -ml-0.5 mr-2 h-5 w-5"
												}
											/>
											<span>User Details</span>
										</button>
									)}
								</Tab>
								<Tab as={Fragment}>
									{({ selected }) => (
										/* Use the `selected` state to conditionally style the selected tab. */
										<button
											className={
												selected
													? "border-indigo-500 text-indigo-600 group inline-flex items-center py-5 px-6 border-b-2 font-medium text-sm"
													: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 group inline-flex items-center py-5 px-6 border-b-2 font-medium text-sm"
											}
										>
											<LockClosedIcon
												className={
													selected
														? "text-indigo-500 -ml-0.5 mr-2 h-5 w-5"
														: "text-gray-400 group-hover:text-gray-500 -ml-0.5 mr-2 h-5 w-5"
												}
											/>
											<span>Manage Password</span>
										</button>
									)}
								</Tab>
							</Tab.List>
						</div>
					</div>
				</div>

				<div>
					<Tab.Panels>
						<Tab.Panel>
							{" "}
							<UserDetails />{" "}
						</Tab.Panel>
						<Tab.Panel>
							{" "}
							<ChangePassword />{" "}
						</Tab.Panel>
					</Tab.Panels>
				</div>
			</Tab.Group>

			<FooterAuth />
		</>
	);
}
