import { useState, Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../app/store";
import {
	ShoppingCartIcon,
	ArrowTopRightOnSquareIcon,
} from "@heroicons/react/20/solid";
import { getPrice, getPriceId, getProductId } from "./productStore";
import axios from "axios";
import { createTempCart } from "../features/cart/cartSlice";
import {
	getClubAnalytics,
	selectSubscription,
} from "../features/subscriptions/subscriptionsSlice";
import { delay } from "@reduxjs/toolkit/dist/utils";

export default function AuthSubscriptions() {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

	const [formData, setFormData] = useState({
		_id: "",
		stripe_id: "",
		stripe_subscription: "",
		email: "",
		product_id: "prod_MpxQLIDWJQ6QTc",
		price_id: "price_1M6HVLAbui4P8tq3huDfHKXC",
		price: 200.0,
		plan_name: "Basic",
		location_name: "",
		location_address: "",
		location_city: "",
		location_state: "",
		location_postal_code: "",
		description: "",
	});

	const {
		_id,
		stripe_subscription,
		stripe_id,
		email,
		product_id,
		price,
		price_id,
		plan_name,
		location_name,
		location_address,
		location_city,
		location_state,
		location_postal_code,
		description,
	} = formData;

	const { user } = useSelector((state: RootState) => state.auth);

	const { subscriptions } = useSelector(
		(state: RootState) => state.subscriptions
	);

	const onChange = (e: { target: { name: any; value: any } }) => {
		setFormData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	let [isOpen, setIsOpen] = useState(false);
	let [isPopulated, setIsPopulated] = useState(false);

	useEffect(() => {
		if (subscriptions.length > 0) {
			setIsPopulated(true);
		}

		if (subscriptions?.length > 0) {
			setIsPopulated(true);
		}

		if (subscriptions.length === 0) {
			setIsPopulated(false);
		}
	}, [subscriptions]);

	function openModal() {
		setIsOpen(true);
	}

	function closeModal() {
		setIsOpen(false);
	}

	function viewClub(_id: string) {
		dispatch(selectSubscription(_id));
		dispatch(getClubAnalytics(_id));
		navigate("/view-club/" + _id);
	}

	const handleSubmitSub = async (e: { preventDefault: () => void }) => {
		e.preventDefault();

		const subscriptionData = {
			_id,
			stripe_subscription,
			stripe_id,
			email,
			product_id,
			price,
			price_id,
			plan_name,
			location_name,
			location_address,
			location_city,
			location_state,
			location_postal_code,
			description,
		};

		const config = {
			headers: {
				Authorization: `Bearer ${user.token}`,
			},
		};

		subscriptionData._id = user._id;
		subscriptionData.product_id = getProductId(subscriptionData.plan_name);
		subscriptionData.price_id = getPriceId(subscriptionData.plan_name);
		subscriptionData.price = getPrice(subscriptionData.plan_name);
		subscriptionData.email = user.email;
		subscriptionData.stripe_id = user.stripe_id;

		//axios.post('http://localhost:3000/api/subscriptions/sub', subscriptionData)

		console.log(subscriptionData);
		const response = await axios.post(
			"http://localhost:3000/api/subscriptions/sub",
			subscriptionData,
			config
		);

		const tempCartItem = {
			_id: "",
			user_id: user._id,
			stripe_subscription: "",
			product_id: subscriptionData.product_id,
			price_id: subscriptionData.price_id,
			price: subscriptionData.price,
			plan_name: subscriptionData.plan_name,
			location_name: subscriptionData.location_name,
			location_address: subscriptionData.location_address,
			location_city: subscriptionData.location_city,
			location_state: subscriptionData.location_state,
			location_postal_code: subscriptionData.location_postal_code,
			description: subscriptionData.description,
			stripe_subscription_id: response.data.subscriptionId,
			added_timestamp: "",
		};

		dispatch(createTempCart(tempCartItem));

		navigate("/checkout/" + response.data.clientSecret);
	};

	return (
		<>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={closeModal}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-4xl bg-white p-6 text-left align-middle shadow-xl transition-all">
									<Dialog.Title
										as="h3"
										className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900"
									>
										Add Subscription
									</Dialog.Title>
									<div className="mt-2">
										<div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
											<div className="w-full max-w-3xl space-y-8">
												<form
													className="mt-8 space-y-6"
													action="#"
													method="POST"
												>
													<input
														type="hidden"
														name="remember"
														defaultValue="true"
														onChange={onChange}
													/>
													<div className="-space-y-px rounded-md shadow-sm">
														<div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
															<div className="md:grid md:grid-cols-3 md:gap-6">
																<div className="col-span-6 sm:col-span-4">
																	<label
																		htmlFor="plan_name"
																		className="block text-sm font-medium text-gray-700"
																	>
																		Plan
																	</label>
																	<select
																		id="plan_name"
																		onChange={onChange}
																		name="plan_name"
																		className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
																	>
																		<option>Basic</option>
																		<option>Management plus</option>
																		<option>Premium</option>
																	</select>
																</div>

																<div className="col-span-6 sm:col-span-4">
																	<label
																		htmlFor="location_name"
																		className="block text-sm font-medium text-gray-700"
																	>
																		Club Name
																	</label>
																	<input
																		id="location_name"
																		name="location_name"
																		type="location_name"
																		required
																		className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
																		placeholder="Club Name"
																		onChange={onChange}
																	/>
																</div>

																<div className="col-span-6 sm:col-span-4">
																	<label
																		htmlFor="location_address"
																		className="block text-sm font-medium text-gray-700"
																	>
																		Street Address
																	</label>
																	<input
																		id="location_address"
																		name="location_address"
																		type="location_address"
																		required
																		className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
																		placeholder="Street Address"
																		onChange={onChange}
																	/>
																</div>

																<div className="col-span-6 sm:col-span-6 lg:col-span-2">
																	<label
																		htmlFor="location_city"
																		className="block text-sm font-medium text-gray-700"
																	>
																		City
																	</label>
																	<input
																		id="location_city"
																		name="location_city"
																		type="location_city"
																		required
																		className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
																		placeholder="City"
																		onChange={onChange}
																	/>
																</div>

																<div className="col-span-6 sm:col-span-6 lg:col-span-2">
																	<label
																		htmlFor="location_state"
																		className="block text-sm font-medium text-gray-700"
																	>
																		State Abbreviation
																	</label>
																	<input
																		id="location_state"
																		name="location_state"
																		type="location_state"
																		maxLength={2}
																		required
																		className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
																		placeholder="State"
																		onChange={onChange}
																	/>
																</div>

																<div className="col-span-6 sm:col-span-6 lg:col-span-2">
																	<label
																		htmlFor="location_postal_code"
																		className="block text-sm font-medium text-gray-700"
																	>
																		Postal Code
																	</label>
																	<input
																		id="location_postal_code"
																		name="location_postal_code"
																		type="location_postal_code"
																		required
																		className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
																		placeholder="Postal Code"
																		onChange={onChange}
																	/>
																</div>

																<div className="col-span-6 sm:col-span-6 lg:col-span-2">
																	<label
																		htmlFor="description"
																		className="block text-sm font-medium text-gray-700"
																	>
																		Club Description
																	</label>
																	<input
																		id="description"
																		name="description"
																		type="description"
																		required
																		className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
																		placeholder="Description of your club patrons will see"
																		onChange={onChange}
																	/>
																</div>
															</div>
														</div>
													</div>

													<div className="mt-4 flex ">
														<button
															type="button"
															className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
															onClick={closeModal}
														>
															Cancel
														</button>

														<button
															type="submit"
															onClick={handleSubmitSub}
															className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
														>
															<span className="mr-3 inset-y-0 left-0 flex items-start pl-3">
																<ShoppingCartIcon
																	className="  h-5 w-5 text-indigo-100 group-hover:text-indigo-400"
																	aria-hidden="true"
																/>
															</span>
															Subscribe
														</button>
													</div>
												</form>
											</div>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>

			<div className="py-4 px-4 sm:px-6 lg:px-8">
				<div className="sm:flex sm:items-center py-6">
					<div className="sm:flex-auto">
						<h1 className="text-xl font-semibold text-gray-900">
							Subscriptions
						</h1>
						<p className="mt-2 text-sm text-gray-700">
							A list of all the subcrpitions on your account by location.
						</p>
					</div>
					<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
						<button
							onClick={openModal}
							type="button"
							className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
						>
							Add Subscription
						</button>
					</div>
				</div>

				{isPopulated ? (
					<div className="mt-8 flex flex-col">
						<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
								<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
									<table className="min-w-full divide-y divide-gray-300">
										<thead className="bg-gray-50">
											<tr>
												<th
													scope="col"
													className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
												>
													Club name
												</th>
												<th
													scope="col"
													className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
												>
													Club ID
												</th>
												<th
													scope="col"
													className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
												>
													Subscripion plan
												</th>
												<th
													scope="col"
													className="relative py-3.5 pl-3 pr-4 sm:pr-6"
												>
													<span className="sr-only">Edit</span>
												</th>
											</tr>
										</thead>
										<tbody className="bg-white">
											{subscriptions.map((subscription, subscriptionIdx) => (
												<tr
													key={subscription._id}
													className={
														subscriptionIdx % 2 === 0 ? undefined : "bg-gray-50"
													}
												>
													<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
														{subscription.location_name}
													</td>
													<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
														{subscription._clubsId}
													</td>
													<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
														{subscription.plan_name}
													</td>
													<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
														<button
															type="button"
															className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
															onClick={() => viewClub(subscription._id)}
														>
															<ArrowTopRightOnSquareIcon
																className="-ml-1 mr-3 h-5 w-5"
																aria-hidden="true"
															/>
															View/Edit
														</button>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				) : (
					<div className="text-center overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 ">
						<div className="text-center relative grid gap-6 bg-white px-5 py-2 sm:gap-8 sm:p-8 ">
							<div className="text-center justify-center">
								<h3 className="text-center mt-2 text-sm font-medium text-gray-900">
									No Subscriptions
								</h3>
								<p className="text-center mt-1 text-sm text-gray-500">
									Get started by adding a subscription
								</p>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
}
