import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import subscriptionsService from "./subscriptionsService";
import { SubscriptionItem } from "../../models/subscription-item.model";
import { AnalyticsModel } from "../../models/analytics.model";
import { RootState } from "../../app/store";

const selectedSubscription: any = "";
const analytics: any = "";

const initialState = {
    subscriptions: [] as SubscriptionItem[],
    selectedSubscription: selectedSubscription ? selectedSubscription : null,
    analytics: analytics ? analytics : null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};

// Create new subscription
export const createSubscription = createAsyncThunk<
    SubscriptionItem,
    SubscriptionItem[],
    { state: RootState }
>("subscriptions/create", async (subscriptionData, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token;
        return await subscriptionsService.createSubscription(
            subscriptionData,
            token
        );
    } catch (error) {
        let message = "Unknown Error";
        if (error instanceof Error)
            message =
                (error.message && error.name && error.stack) ||
                error.message ||
                error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// Update Club details
export const editSubscriptionDetails = createAsyncThunk<
    SubscriptionItem,
    SubscriptionItem,
    { state: RootState }
>("subscriptions/edit", async (editData: SubscriptionItem, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token;
        return await subscriptionsService.updateSubscriptionDetails(
            editData,
            token
        );
    } catch (error) {
        let message = "Unknown Error";
        if (error instanceof Error)
            message =
                (error.message && error.name && error.stack) ||
                error.message ||
                error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// Update Subscription Plan
export const editSubscriptionPlan = createAsyncThunk<
    SubscriptionItem,
    SubscriptionItem,
    { state: RootState }
>("subscriptions/editPlan", async (editData: SubscriptionItem, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token;
        return await subscriptionsService.updateSubscriptionPlan(
            editData,
            token
        );
    } catch (error) {
        let message = "Unknown Error";
        if (error instanceof Error)
            message =
                (error.message && error.name && error.stack) ||
                error.message ||
                error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// Cancel Subscription Plan
export const cancelSubscriptionPlan = createAsyncThunk<
    SubscriptionItem,
    SubscriptionItem,
    { state: RootState }
>("subscriptions/cancelPlan", async (editData: SubscriptionItem, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token;
        return await subscriptionsService.cancelSubscriptionPlan(
            editData,
            token
        );
    } catch (error) {
        let message = "Unknown Error";
        if (error instanceof Error)
            message =
                (error.message && error.name && error.stack) ||
                error.message ||
                error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// Get user subscriptions
export const getSubscriptions = createAsyncThunk<
    SubscriptionItem[],
    string,
    { state: RootState }
>("subscriptions/getAll", async (token, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token;
        return await subscriptionsService.getSubscriptions(token);
    } catch (error) {
        let message = "Unknown Error";
        if (error instanceof Error)
            message =
                (error.message && error.name && error.stack) ||
                error.message ||
                error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// Get club analytics
export const getClubAnalytics = createAsyncThunk<
    AnalyticsModel,
    string,
    { state: RootState }
>("subscriptions/getAnalytics", async (arg, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token;
        const _clubsId = thunkAPI
            .getState()
            .subscriptions.subscriptions.find((x) => x._id === arg)!._clubsId;
        return await subscriptionsService.getClubAnalyticsData(_clubsId, token);
    } catch (error) {
        let message = "Unknown Error";
        if (error instanceof Error)
            message =
                (error.message && error.name && error.stack) ||
                error.message ||
                error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// Delete user subscription
export const deleteSubscription = createAsyncThunk<
    SubscriptionItem,
    string,
    { state: RootState }
>("subscriptions/delete", async (id, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token;
        return await subscriptionsService.deleteSubscription(id, token);
    } catch (error) {
        let message = "Unknown Error";
        if (error instanceof Error)
            message =
                (error.message && error.name && error.stack) ||
                error.message ||
                error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const subscriptionsSlice = createSlice({
    name: "subscription",
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = "";
        },
        selectSubscription: (state, action: PayloadAction<string>) => {
            state.selectedSubscription = state.subscriptions.find(
                (x) => x._id === action.payload
            );
        },
        clearSelectedSubscription: (state) => {
            state.selectedSubscription = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createSubscription.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createSubscription.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.subscriptions.push(action.payload);
            })
            .addCase(createSubscription.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                if (typeof action.payload === "string") {
                    state.message = action.payload;
                }
            })
            .addCase(getClubAnalytics.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getClubAnalytics.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.analytics = action.payload;
            })
            .addCase(getClubAnalytics.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                if (typeof action.payload === "string") {
                    state.message = action.payload;
                }
            })
            .addCase(editSubscriptionDetails.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(editSubscriptionDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.subscriptions = state.subscriptions.filter(
                    (item, index) => item._id !== action.payload._id
                );
                state.subscriptions = [...state.subscriptions, action.payload];
                state.selectedSubscription = action.payload;
            })
            .addCase(editSubscriptionDetails.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                if (typeof action.payload === "string") {
                    state.message = action.payload;
                }
            })
            .addCase(editSubscriptionPlan.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(editSubscriptionPlan.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.subscriptions = state.subscriptions.filter(
                    (item, index) => item._id !== action.payload._id
                );
                state.subscriptions = [...state.subscriptions, action.payload];
                state.selectedSubscription = action.payload;
            })
            .addCase(editSubscriptionPlan.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                if (typeof action.payload === "string") {
                    state.message = action.payload;
                }
            })
            .addCase(cancelSubscriptionPlan.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(cancelSubscriptionPlan.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.subscriptions = state.subscriptions.filter(
                    (item, index) => item._id !== action.payload._id
                );
                state.subscriptions = [...state.subscriptions, action.payload];
                state.selectedSubscription = action.payload;
            })
            .addCase(cancelSubscriptionPlan.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                if (typeof action.payload === "string") {
                    state.message = action.payload;
                }
            })
            .addCase(getSubscriptions.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getSubscriptions.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.subscriptions = action.payload;
            })
            .addCase(getSubscriptions.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                if (typeof action.payload === "string") {
                    state.message = action.payload;
                }
            })
            .addCase(deleteSubscription.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteSubscription.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.subscriptions = state.subscriptions.filter(
                    (subscription) => subscription._id !== action.payload._id
                );
            })
            .addCase(deleteSubscription.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                if (typeof action.payload === "string") {
                    state.message = action.payload;
                }
            });
    },
});

export const { reset, selectSubscription, clearSelectedSubscription } =
    subscriptionsSlice.actions;
export default subscriptionsSlice.reducer;
