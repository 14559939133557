export default function Contact() {
	return (
		<div className="bg-white py-24 sm:py-32">
			<div className="mx-auto max-w-7xl px-6 lg:px-8">
				<div className="mx-auto max-w-2xl space-y-16 divide-y divide-gray-100 lg:mx-0 lg:max-w-none">
					<div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
						<div>
							<h2 className="text-3xl font-bold tracking-tight text-gray-900">
								Get in touch
							</h2>
							<p className="mt-4 leading-7 text-gray-600">
								Reach out to our team for information or to schedule a demo
							</p>
						</div>
						<div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
							<div className="rounded-2xl bg-gray-50 p-7">
								<h3 className="text-base font-semibold leading-7 text-gray-900">
									Sales - Ryan Henning
								</h3>
								<dl className="mt-3 space-y-1 text-sm leading-6 text-gray-600">
									<div>
										<dt className="sr-only">Email</dt>
										<dd>
											<a
												className="font-semibold text-indigo-600"
												href="mailto:ryan.henning@club-spotlight.com"
											>
												ryan.henning@club-spotlight.com
											</a>
										</dd>
									</div>
									<div className="mt-1">
										<dt className="sr-only">Phone number</dt>
										<dd>+1 (214) 603-5789</dd>
									</div>
								</dl>
							</div>
							<div className="rounded-2xl bg-gray-50 p-10">
								<h3 className="text-base font-semibold leading-7 text-gray-900">
									Technical Questions
								</h3>
								<dl className="mt-3 space-y-1 text-sm leading-6 text-gray-600">
									<div>
										<dt className="sr-only">Email</dt>
										<dd>
											<a
												className="font-semibold text-indigo-600"
												href="mailto:admin@club-spotlight.com"
											>
												admin@club-spotlight.com
											</a>
										</dd>
									</div>
								</dl>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
