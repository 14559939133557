const productsArray = [
    {
        price_id: "price_1M6HVLAbui4P8tq3huDfHKXC", 
        product_id: "prod_MpxQLIDWJQ6QTc",
        title: "Basic",
        price: 200.00
    },
    {
        price_id: "price_1M6HW5Abui4P8tq3TDbcPxba",
        product_id: "prod_MpxQCja9TzDXEy",
        title: "Management plus",
        price: 350.00
    },
    {
        price_id: "price_1M6HWcAbui4P8tq3w0E6AL05",
        product_id: "prod_MpxRlCkvsmesSI",
        title: "Premium",
        price: 500.00
    }
];

function getProductData(id: string) {
    let productData = productsArray.find(product => product.price_id === id);

    if (productData === undefined) {
        console.log("Product data does not exist for ID: " + id);
        return undefined;
    }

    return productData;
}

function getPriceId(title: string) {  let productData = productsArray.find(product => product.title === title);

    if (productData === undefined) {
        console.log("Product data does not exist for ID: " + title);
        return '0';
    }

    return productData.price_id; }

    function getProductId(title: string) {  let productData = productsArray.find(product => product.title === title);

        if (productData === undefined) {
            console.log("Product data does not exist for ID: " + title);
            return '0';
        }
    
        return productData.product_id; }

        function getPrice(title: string) {  let productData = productsArray.find(product => product.title === title);

            if (productData === undefined) {
                console.log("Product data does not exist for ID: " + title);
                return 0;
            }
        
            return productData.price; }

export { productsArray, getProductData, getPriceId, getProductId, getPrice };