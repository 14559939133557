import {
	configureStore,
	ThunkAction,
	Action,
	combineReducers,
	AnyAction,
	CombinedState,
} from "@reduxjs/toolkit";
import subscriptionsReducer from "../features/subscriptions/subscriptionsSlice";
import cartReducer from "../features/cart/cartSlice";
import authReducer from "../features/auth/authSlice";
import invoicesReducer from "../features/invoices/invoicesSlice";
import { CartItem } from "../models/cart-item.model";
import { InvoiceItem } from "../models/invoice-item.model";
import { SubscriptionItem } from "../models/subscription-item.model";
import logger from "redux-logger";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const combinedReducer = combineReducers({
	cart: cartReducer,
	auth: authReducer,
	subscriptions: subscriptionsReducer,
	invoices: invoicesReducer,
});

const rootReducer = (
	state:
		| CombinedState<{
				cart: {
					cart: CartItem[];
					isError: boolean;
					isSuccess: boolean;
					isLoading: boolean;
					message: string;
				};
				auth: {
					user: any;
					isError: boolean;
					isSuccess: boolean;
					isLoading: boolean;
					message: string;
				};
				subscriptions: {
					subscriptions: SubscriptionItem[];
					selectedSubscription: any;
					analytics: any;
					isError: boolean;
					isSuccess: boolean;
					isLoading: boolean;
					message: string;
				};
				invoices: {
					invoices: InvoiceItem[];
					selectedInvoice: any;
					isError: boolean;
					isSuccess: boolean;
					isLoading: boolean;
					message: string;
				};
		  }>
		| undefined,
	action: AnyAction
) => {
	if (action.type === "auth/logout") {
		state = undefined;
	}
	return combinedReducer(state, action);
};

const persistConfig = {
	key: "root",
	storage,
	//whitelist: [authSlice.name]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
	devTools: process.env.NODE_ENV !== "production",
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
