import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useNavigate, useParams } from "react-router-dom";
import {
	ChartBarIcon,
	BuildingStorefrontIcon,
	ShoppingCartIcon,
} from "@heroicons/react/20/solid";
import { FooterAuth } from "../components/FooterAuth";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../app/store";
import { Fragment } from "react";
import { Dialog, Tab, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { cartReset } from "../features/cart/cartSlice";
import { getSubscriptions } from "../features/subscriptions/subscriptionsSlice";
import AuthViewClubHeader from "../components/authViewClubHeader";
import ViewClubDetails from "../components/viewClubDetails";
import ViewClubAnalytics from "../components/viewClubAnalytics";

const ViewClub = () => {
	let { subscriptionId } = useParams();

	const { user } = useSelector((state: RootState) => state.auth);

	const { subscriptions } = useSelector(
		(state: RootState) => state.subscriptions
	);

	return (
		<>
			<AuthViewClubHeader />

			<Tab.Group>
				<div>
					<div>
						<div className="border-b border-gray-200">
							<Tab.List className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
								<Tab as={Fragment}>
									{({ selected }) => (
										/* Use the `selected` state to conditionally style the selected tab. */
										<button
											className={
												selected
													? "border-indigo-500 text-indigo-600 group inline-flex items-center py-5 px-6 border-b-2 font-medium text-sm"
													: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 group inline-flex items-center py-5 px-6 border-b-2 font-medium text-sm"
											}
										>
											<BuildingStorefrontIcon
												className={
													selected
														? "text-indigo-500 -ml-0.5 mr-2 h-5 w-5"
														: "text-gray-400 group-hover:text-gray-500 -ml-0.5 mr-2 h-5 w-5"
												}
											/>
											<span>Subscription/Club Details</span>
										</button>
									)}
								</Tab>
								<Tab as={Fragment}>
									{({ selected }) => (
										/* Use the `selected` state to conditionally style the selected tab. */
										<button
											className={
												selected
													? "border-indigo-500 text-indigo-600 group inline-flex items-center py-5 px-6 border-b-2 font-medium text-sm"
													: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 group inline-flex items-center py-5 px-6 border-b-2 font-medium text-sm"
											}
										>
											<ChartBarIcon
												className={
													selected
														? "text-indigo-500 -ml-0.5 mr-2 h-5 w-5"
														: "text-gray-400 group-hover:text-gray-500 -ml-0.5 mr-2 h-5 w-5"
												}
											/>
											<span>Club Analytics</span>
										</button>
									)}
								</Tab>
							</Tab.List>
						</div>
					</div>
				</div>

				<div>
					<Tab.Panels>
						<Tab.Panel>
							{" "}
							<ViewClubDetails />{" "}
						</Tab.Panel>
						<Tab.Panel>
							{" "}
							<ViewClubAnalytics />{" "}
						</Tab.Panel>
					</Tab.Panels>
				</div>
			</Tab.Group>

			<FooterAuth />
		</>
	);
};

export default ViewClub;
