import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { InvoiceItem } from "../../models/invoice-item.model";
import invoicesService from "./invoicesService";

const selectedInvoice: any = "";

const initialState = {
    invoices: [] as InvoiceItem[],
    selectedInvoice: selectedInvoice ? selectedInvoice : null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};

// Get user subscriptions
export const getInvoices = createAsyncThunk<
    InvoiceItem[],
    string,
    { state: RootState }
>("invoices/getAll", async (token, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token;
        return await invoicesService.getInvoices(token);
    } catch (error) {
        let message = "Unknown Error";
        if (error instanceof Error)
            message =
                (error.message && error.name && error.stack) ||
                error.message ||
                error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const invoicesSlice = createSlice({
    name: "invoice",
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = "";
        },
        selectInvoice: (state, action: PayloadAction<string>) => {
            state.selectedInvoice = state.invoices.find(
                (x) => x._id === action.payload
            );
        },
        clearSelectedInvoice: (state) => {
            state.selectedInvoice = null;
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(getInvoices.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getInvoices.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.invoices = action.payload;
            })
            .addCase(getInvoices.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                if (typeof action.payload === "string") {
                    state.message = action.payload;
                }
            });
    },
});

export const { reset, selectInvoice, clearSelectedInvoice } =
    invoicesSlice.actions;
export default invoicesSlice.reducer;
