import { EyeIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { CursorArrowRippleIcon } from "@heroicons/react/24/outline";
import { RootState } from "../app/store";
import OrderByDayBarChart from "./charts/orderByDayBarChart";

export default function ViewClubAnalytics() {
	const { analytics } = useSelector((state: RootState) => state.subscriptions);
	const cards = [
		{ name: "Views", icon: EyeIcon, amount: analytics.viewsCount },
		{
			name: "Performer Orders",
			icon: CursorArrowRippleIcon,
			amount: analytics.performerOrdersCount,
		},
		{
			name: "Menu Orders",
			icon: CursorArrowRippleIcon,
			amount: analytics.menuOrdersCount,
		},
	];

	return (
		<>
			<div className="mt-5">
				<div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
					<h2 className="text-lg font-medium leading-6 text-gray-900">
						Activity - Last 14 Days
					</h2>
					<div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
						{/* Card */}
						{cards.map((card) => (
							<div
								key={card.name}
								className="overflow-hidden rounded-lg bg-white shadow"
							>
								<div className="p-5">
									<div className="flex items-center">
										<div className="flex-shrink-0">
											<card.icon
												className="h-6 w-6 text-gray-400"
												aria-hidden="true"
											/>
										</div>
										<div className="ml-5 w-0 flex-1">
											<dl>
												<dt className="truncate text-sm font-medium text-gray-500">
													{card.name}
												</dt>
												<dd>
													<div className="text-lg font-medium text-gray-900">
														{card.amount}
													</div>
												</dd>
											</dl>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>

				<h2 className="mx-auto mt-8 max-w-6xl px-4 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">
					Recent activity
				</h2>
			</div>

			<OrderByDayBarChart />
		</>
	);
}
