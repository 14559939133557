import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DashboardPage from "./pages/dashboard";
import LoginPage from "./pages/login";
import RegisterPage from "./pages/register";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthDashboard from "./pages/authDashboard";
import CheckoutPage from "./pages/checkout";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ViewClub from "./pages/viewClub";
import ViewInvoice from "./pages/viewInvoice";
import ViewProfile from "./pages/viewProfile";
//const key =  `${process.env.STRIPE_PUB_KEY}`;

const stripePromise = loadStripe(
	"pk_test_51M6HQ2Abui4P8tq3lZwtLCVl4h22cbVBlMITUhJMojmn6QR1Q3yD9Mv3e2b1UHRbZcxiZ2R2JIxI6BwSEhvvHnYj00ZwJIO0x1"
);

function App(this: any) {
	return (
		<Elements stripe={stripePromise}>
			<>
				<Router>
					<div className="container">
						<main>
							<Routes>
								<Route path="/*" element={<DashboardPage />} />
								<Route path="/user" element={<AuthDashboard />} />
								<Route path="/login" element={<LoginPage />} />
								<Route path="/register" element={<RegisterPage />} />
								<Route
									path="/checkout/:clientSecret"
									element={<CheckoutPage />}
								/>
								<Route
									path="/view-club/:subscriptionId"
									element={<ViewClub />}
								/>
								<Route
									path="/view-invoice/:invoiceId"
									element={<ViewInvoice />}
								/>
								<Route path="/userProfile" element={<ViewProfile />} />
							</Routes>
						</main>
					</div>
				</Router>
				<ToastContainer />
			</>
		</Elements>
	);
}

export default App;
