import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useNavigate, useParams } from "react-router-dom";
import {
	QuestionMarkCircleIcon,
	ShoppingCartIcon,
} from "@heroicons/react/20/solid";
import { FooterAuth } from "../components/FooterAuth";
import CheckOutHeader from "../components/checkOutHeader";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../app/store";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { cartReset } from "../features/cart/cartSlice";
import { getSubscriptions } from "../features/subscriptions/subscriptionsSlice";
import { getInvoices } from "../features/invoices/invoicesSlice";

const CheckoutPage = () => {
	let { clientSecret } = useParams();

	const [openSuccess, setOpenSuccess] = useState(false);

	const { cart } = useSelector((state: RootState) => state.cart);

	const { user } = useSelector((state: RootState) => state.auth);

	const dispatch = useDispatch<AppDispatch>();

	const onChange = (e: { target: { name: any; value: any } }) => {
		setFormData2((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	const [formData2, setFormData2] = useState({
		card_name: "",
	});

	const { card_name } = formData2;

	const navigate = useNavigate();

	// Get the lookup key for the price from the previous page redirect.

	const [messages, _setMessages] = useState("");
	const [paymentIntent, setPaymentIntent] = useState<any | undefined>();

	// helper for displaying status messages.
	const setMessage = (message: string | undefined) => {
		_setMessages(`${messages}\n\n${message}`);
	};

	// Initialize an instance of stripe.
	const stripe = useStripe();
	const elements = useElements();

	if (!stripe || !elements) {
		// Stripe.js has not loaded yet. Make sure to disable
		// form submission until Stripe.js has loaded.
		return <>''</>;
	}

	if (!clientSecret) {
		// Stripe.js has not loaded yet. Make sure to disable
		// form submission until Stripe.js has loaded.
		return <>''</>;
	}

	// When the subscribe-form is submitted we do a few things:
	//
	//   1. Tokenize the payment method
	//   2. Create the subscription
	//   3. Handle any next actions like 3D Secure that are required for SCA.
	const handleSubmit = async (e: { preventDefault: () => void }) => {
		e.preventDefault();

		if (!clientSecret) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			return <>''</>;
		}

		// Get a reference to a mounted CardElement. Elements knows how
		// to find your CardElement because there can only ever be one of
		// each type of element.
		const cardElement = elements.getElement(CardElement);

		// Use card Element to tokenize payment details
		let { error, paymentIntent } = await stripe.confirmCardPayment(
			clientSecret,
			{
				payment_method: {
					card: cardElement!,
					billing_details: {
						name: formData2.card_name,
					},
				},
			}
		);

		if (error) {
			// show error and collect new card details.
			setMessage(error.message);
			//return;
		}
		setPaymentIntent(paymentIntent);
		console.log(paymentIntent);
	};

	function cancelOrder() {
		dispatch(cartReset());
		navigate("/user");
	}

	function closeConfirmation() {
		setOpenSuccess(false);
		navigate("/user");
		dispatch(cartReset());
		dispatch(getSubscriptions(user.token));
		dispatch(getInvoices(user.token));
	}

	if (
		paymentIntent &&
		paymentIntent.status === "succeeded" &&
		openSuccess === false
	) {
		//toast.success('Payment Succeeded. Subscription Added')
		//navigate('/success')
		setOpenSuccess(true);

		//return;
	}

	return (
		<>
			<CheckOutHeader />

			<Transition.Root show={openSuccess} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={setOpenSuccess}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-10 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
									<div>
										<div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
											<CheckIcon
												className="h-6 w-6 text-green-600"
												aria-hidden="true"
											/>
										</div>
										<div className="mt-3 text-center sm:mt-5">
											<Dialog.Title
												as="h3"
												className="text-lg font-medium leading-6 text-gray-900"
											>
												Payment successful
											</Dialog.Title>
											<div className="mt-2">
												<p className="text-sm text-gray-500">
													Your subscription has been created. Our team will be
													in touch about shipping your QR codes shortly.
												</p>
											</div>
										</div>
									</div>
									<div className="mt-5 sm:mt-6">
										<button
											type="button"
											className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
											onClick={closeConfirmation}
										>
											Go back to dashboard
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>

			<div className="md:container md:mx-auto  px-8 box-border md:box-conten">
				<div className="flex min-h-0 items-center justify-center p-2 text-center relative z-5 m-2">
					<h1 className="mt-2 text-center text-2xl font-bold tracking-tight text-gray-900">
						Complete Payment
					</h1>
				</div>

				<div className="w-full  space-y-3 justify-center object-center">
					<div className=" rounded-md shadow-sm justify-center py-2 px-4 object-center">
						<section
							aria-labelledby="summary-heading"
							className="mt-2 rounded-lg bg-gray-50 px-4 py-4 sm:p-6 lg:col-span-5 lg:mt-0 lg:p-8"
						>
							<h2
								id="summary-heading"
								className="text-lg pb-10 font-medium text-gray-900 justify-center object-center text-center"
							>
								Order Summary
								<br></br>
							</h2>

							<p className="text-sm text-gray-600">
								Subcription Plan: {cart[0].plan_name} <br></br>
							</p>

							<dl className="mt-6 space-y-4">
								<div className="flex items-center justify-between">
									<dt className="text-sm text-gray-600">Subscription Price</dt>
									<dd className="text-sm font-medium text-gray-900">
										${Math.round((cart[0].price * 100) / 100).toFixed(2)}
									</dd>
								</div>

								<div className="flex items-center justify-between border-t border-gray-200 pt-4">
									<dt className="flex text-sm text-gray-600">
										<span>Tax estimate</span>
										<a
											href="home"
											className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500"
										>
											<span className="sr-only">
												Learn more about how tax is calculated
											</span>
											<QuestionMarkCircleIcon
												className="h-5 w-5"
												aria-hidden="true"
											/>
										</a>
									</dt>
									<dd className="text-sm font-medium text-gray-900">$0.00</dd>
								</div>
								<div className="flex items-center justify-between border-t border-gray-200 pt-4">
									<dt className="text-base font-medium text-gray-900">
										Order total
									</dt>
									<dd className="text-base font-medium text-gray-900">
										${Math.round((cart[0].price * 100) / 100).toFixed(2)}
									</dd>
								</div>

								<p className="block text-base font-medium text-gray-700 py-3">
									Reciept will be emailed to: {user.email}
								</p>
							</dl>
						</section>

						<form onSubmit={handleSubmit} className="mt-8 space-y-6">
							<label className="block text-sm font-medium text-gray-700 py-3">
								Card Holder Full Name
								<input
									type="text"
									id="card_name"
									defaultValue={card_name}
									onChange={onChange}
									className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
								/>
							</label>

							<CardElement />

							<div className="mt-4 flex space-x-5 ">
								<button className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-9 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
									<span className="mr-3 inset-y-0 left-0 flex items-start pl-3">
										<ShoppingCartIcon
											className="  h-5 w-5 text-indigo-100 group-hover:text-indigo-400"
											aria-hidden="true"
										/>
									</span>
									Pay
								</button>

								<button
									type="button"
									className="rounded-md border border-gray-300 bg-white py-2 px-9 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
									onClick={cancelOrder}
								>
									Cancel
								</button>
							</div>

							<div>{messages}</div>
						</form>
					</div>
				</div>
			</div>

			<FooterAuth />
		</>
	);
};

export default CheckoutPage;
